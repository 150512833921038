import React, { useState } from 'react';
import AnimierterText from './AnimierterText';
import Svg_Container from './Svg_Container';

const Hauptseite = () => {
    const [signalReceived, setSignalReceived] = useState(false);

    const handleAnimationComplete = () => {
        console.log('Animation abgeschlossen');
        setTimeout(() => {
            setSignalReceived(true); // Setze das Signal, dass die Animation abgeschlossen ist
        }, 1000); // Warte 1000 Millisekunden (1 Sekunde)
    };

    return (
        <div>
            <Svg_Container />
        </div>
    );
};

export default Hauptseite;
